import axios from 'axios';
import { getAccessToken } from '../context';
import { sleep } from '../util/sleep';

export async function makeRequest(method, path, data, id, isForm, retries = 4) {
  try {
    const url = `${process.env.REACT_APP_HEAVYSET_API}/${path}${id ? `/${id}` : ''}`;
    let response;
    const headers = {
      Authorization: `Bearer ${getAccessToken()}`
    };
    if (isForm) {
      headers['Content-Type'] = 'multipart/form-data';
    }
    if (method === 'delete') {
      response = await axios.delete(url, { headers });
    } else {
      response = await axios[method](url, data, { headers });
    }
    return { data: response.data };
  } catch (ex) {
    if (retries > 0) {
      await sleep(500);
      return await makeRequest(method, path, data, id, isForm, retries - 1);
    }
    return { errorMessage: ex.response?.data?.message || ex.message };
  }
}
