import { makeRequest } from './make-request';
import { fetchWithPromise } from './fetch-with-cache';
import { jwtToken } from '../context';

export async function submitEmail(email, optIn) {
  let trustedFormCertUrl;
  if (jwtToken.trustedForm) {
    try {
      const certTokenInput = document.querySelector('input[name="xxTrustedFormCertUrl"]');
      trustedFormCertUrl = certTokenInput ? certTokenInput.value : '';
    } catch (ex) {
      console.error('error getting cert inputs');
      console.error(ex);
    }
  }
  return await makeRequest('post', 'appointment/page/api/lead/email', { email, optIn, trustedFormCertUrl });
}

export async function submitProduct(payload) {
  return await makeRequest('post', 'appointment/page/api/lead/product', payload);
}

export async function submitHearAbout(payload) {
  return await makeRequest('post', 'appointment/page/api/lead/hear-about', payload);
}

/**
 * Submits staff information.
 *
 * @param {Object} payload - The payload containing staff information.
 * @param {string} payload.staffId - The staff ID.
 * @returns {Promise<string>} - 'success'
 */
export async function submitStaff(payload) {
  return await makeRequest('post', 'appointment/page/api/lead/staff', payload);
}

export async function submitDetails(payload) {
  return await makeRequest('post', 'appointment/page/api/lead/details', payload);
}

/**
 * Submits staff information.
 *
 * @param {Object} payload - The payload containing staff information.
 * @param {string} payload.homeYear - The staff ID.
 * @returns {Promise<string>} - 'success'
 */
export async function submitHomeYear(payload) {
  return await makeRequest('post', 'appointment/page/api/lead/home-year', payload);
}

export async function submitAddress(payload) {
  return await makeRequest('post', 'appointment/page/api/lead/address', payload);
}

export async function submitLead(userInfo) {
  let trustedFormPingUrl;
  if (jwtToken.trustedForm) {
    try {
      const certTokenInput = document.querySelector('input[name="xxTrustedFormCertUrl"]');
      const pingUrlInput = document.querySelector('input[name="xxTrustedFormPingUrl"]');

      const trustedFormCertUrl = certTokenInput ? certTokenInput.value : '';
      trustedFormPingUrl = pingUrlInput ? pingUrlInput.value : '';
      console.log('', { trustedFormCertUrl, trustedFormPingUrl });

      // Add the cert URL to userInfo
      userInfo.trustedFormCertUrl = trustedFormCertUrl;
      userInfo.trustedFormPingUrl = trustedFormPingUrl;
    } catch (ex) {
      console.error('error getting cert inputs');
      console.error(ex);
    }
  }
  return await makeRequest('post', 'appointment/page/api/lead', userInfo);
}

export async function submitContact(contactInfo) {
  return await makeRequest('post', 'appointment/page/api/contact', contactInfo);
}

export async function getTimeSlots() {
  return await fetchWithPromise('get-time-slots', 'appointment/page/api/slots');
}

export async function submitAppointment(slotInfo) {
  return await makeRequest('post', 'appointment/page/api/appointment', slotInfo);
}

export async function getGoogleReviewInfo(placeId) {
  return await fetchWithPromise(`get-review-${placeId}`, 'appointment/page/api/google-review', { placeId });
}

export async function getCountries(limit, search) {
  const key = `get-countries-${limit},${search}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'appointment/page/api/countries', {
    limit,
    search
  });
  return { records: data, errorMessage };
}

export async function getStaff(limit, search) {
  const key = `get-staff-${limit},${search}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'appointment/page/api/staff', {
    limit,
    search
  });
  const staff = data?.staff || [];
  return { records: staff, errorMessage };
}
